/* Custom CSS Variables - apply to lk theme */
[data-lk-theme="default"] {
  --panel-title-height: 51px;
  --chat-input-height: 69px;
  --control-bar-mobile-height: 128px;
}

/* General */
.lk-custom-theme .lk-room-container {
  @apply font-sans;
}

.lk-custom-theme .lk-room-container {
  @apply bg-transparent;
}

.lk-custom-theme .lk-grid-layout-wrapper {
  @apply bg-grey-900;
  @apply rounded-lg;
  height: calc(100% - var(--control-bar-mobile-height));
}

@media only screen and (min-width: 1024px) {
  .lk-custom-theme .lk-grid-layout-wrapper {
    height: calc(100% - var(--lk-control-bar-height));
  }
}

.lk-custom-theme .lk-grid-layout {
  @apply h-auto;
}

.lk-custom-theme .lk-focus-layout {
  grid-template-columns: 1fr 5fr;
}

@media only screen and (max-width: 600px) {
  .lk-custom-theme .lk-focus-layout {
    grid-template-columns: 1fr;
    grid-template-rows: 5fr 2fr;
  }
}

.lk-custom-theme .lk-focus-layout-wrapper {
  height: 100%;
}

.lk-custom-theme .lk-control-bar {
  @apply border-transparent;
  @apply px-6;
  @apply pb-8;
  @apply max-h-fit;
}

/* Buttons */
.lk-custom-theme button.lk-button {
  @apply rounded-sm;
  @apply bg-grey-800;
  @apply text-grey-0;
  @apply transition;
  @apply duration-100;
}

.lk-custom-theme button.lk-button.active {
  @apply bg-grey-0;
  @apply text-grey-900;
}

.lk-custom-theme button.lk-button.lk-button-menu {
  @apply bg-grey-800/50;
  @apply rounded-r-sm;
  @apply pl-2;
}

.lk-custom-theme .lk-button-menu::after {
  @apply ml-[0.25rem];
}

@media only screen and (min-width: 1024px) {
  .lk-custom-theme .lk-button-menu::after {
    @apply ml-[0.5rem];
  }
}

.lk-custom-theme button.lk-disconnect-button {
  @apply rounded-sm;
}

/* Side panel - not a lk component, but wraps around chat and agenda (also not a lk compoent) */
.lk-custom-theme .side-panel {
  height: calc(100% - var(--control-bar-mobile-height));
}

.lk-custom-theme .side-panel-content {
  height: calc(100% - var(--panel-title-height));
}

@media only screen and (min-width: 1024px) {
  .lk-custom-theme .side-panel {
    height: calc(100% - var(--lk-control-bar-height));
  }
}

/* Chat styles */
.lk-custom-theme .lk-chat {
  @apply bg-grey-0;
  @apply block;
  @apply rounded-lg;
  @apply static;
  padding-bottom: 0.5rem;
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .lk-custom-theme .lk-chat {
    height: 100%;
  }
}

.lk-custom-theme .lk-chat .lk-chat-messages {
  @apply block;
  @apply overflow-y-auto;
  height: calc(100% - var(--chat-input-height));
  max-height: initial;
}

.lk-custom-theme .lk-chat-messages .lk-chat-entry {
  margin-bottom: var(--lk-grid-gap);
}

.lk-custom-theme
  .lk-chat-entry[data-lk-message-origin="local"]
  .lk-message-body {
  @apply bg-grey-800;
  @apply text-grey-100;
}

.lk-custom-theme .lk-chat-entry .lk-meta-data {
  @apply text-grey-700;
}

.lk-custom-theme .lk-chat-form-input {
  @apply text-grey-800;
}

.lk-custom-theme input.lk-form-control.lk-chat-form-input {
  @apply bg-grey-100;
  @apply text-grey-800;
  @apply border-current;
}

.lk-custom-theme .lk-chat-link {
  @apply underline;
}

/* Grid pagination */
.lk-custom-theme .lk-pagination-count {
  @apply p-2;
  @apply text-center;
  @apply min-w-[4rem];
}

.lk-custom-theme .lk-pagination-indicator {
  @apply bg-transparent;
  @apply bottom-0;
}

/* Hide focus toggle button - TODO: this is temp until we can get the functionality working */
.lk-custom-theme .lk-participant-tile:hover .lk-focus-toggle-button {
  @apply opacity-0;
  @apply hidden;
}
