.mdx,
body .sun-editor,
.sun-editor-editable.mdx {
  @apply font-sans font-normal leading-[115%] tracking-tightest text-[1.375rem]
}

.mdx h1,
.mdx h2,
.mdx h3,
.mdx h4,
.mdx h5,
.mdx h6 {
  @apply font-sans font-bold leading-[100%] tracking-tightest mb-6 last:mb-0
}

.mdx h1 {
  @apply text-[2.375rem] md:text-[2.875rem] lg:text-[3.5rem]
}

.mdx h2 {
  @apply font-medium text-[2.25rem] md:text-[2.625rem] lg:text-[3rem]
}

.mdx h3 {
  @apply font-black leading-[120%] text-[1.75rem] mb-4
}

.mdx h4 {
  @apply text-[1.375rem] mb-3
}

.mdx h5 {
  @apply text-[1.125rem] mb-3
}

.mdx h6 {
  @apply text-[1rem] mb-3
}

.mdx p,
.mdx ul,
.mdx ol {
  @apply mb-4 last:mb-0
}

.mdx ul {
  @apply list-disc pl-10
}

.mdx ol {
  @apply list-decimal pl-10
}

.mdx blockquote {
  @apply opacity-70 italic pl-10 border-l border-l-current mb-6 last:mb-0
}

.mdx a {
  @apply underline
}

.mdx a:hover {
  @apply opacity-70
}

.mdx img {
  @apply my-10 last:mb-0
}

.change-project .mdx, .agenda-content-block .mdx {
  @apply text-[1.125rem] leading-[125%] font-normal
}

.change-project .mdx p,
.change-project .mdx ul,
.change-project .mdx ol {
  @apply mb-6 last:mb-0
}

/* Fixes video embed for RTE */
.mdx .se-video-container figure {
  padding-bottom: 0 !important;
  @apply max-w-full aspect-video mx-auto my-6
}
